// @ts-ignore
import Raphael from 'raphael';
import {MenuItem,ContextMenuCreator} from '../ContextMenu';

interface ICanvas {
    connectTextArray:Array<any>
    nodeArray:Array<any>
}
export default class FlowDesignVar {
    public static r:Raphael//画布对象
    public static connectIds:Array<any>=[]//[beganNode节点ID####endNode节点ID,.....]
    public static connectNodes:Array<any>=[]//要连线的两个节点对象；[{obj1:beganNode,obj2:endNode},.....]
    public static canvas:ICanvas={connectTextArray:[],nodeArray:[]}//connectTextArray：所有连线节点上面的文字节点；nodeArray：所有的流程节点（包括开始、结束）
    //数据库中查询出来的连线节点上的文本节点属性设置，和connectTextArray的区别是：connectTextArray数组存放的是整个文本节点，包括节点设置的属性
    //connectProperties数组包含的是【{beganId:'',endId:'',began_endId:'',nodeProperty:{name:'',condition:'',desc:''}},.....】
    public static connectProperties:Array<any>=[]

    public static inst:any={}//调用js.flow.ts的对象模块（DefineCard模块对象）
    public static engine:any={}//引擎对象（CardEngine）

    public static connStatus:boolean=false
    public static startNodeId:string=''//画连线的时候，最先点击的节点，即开始节点
    public static fontCss:any={"font-size": 15, "font-family":"宋体","font-weight":"bold"}//每个节点上面有text孩子节点，这里是text节点的字体样式
    public static connectPoint:string="connectPoint";

    //右键菜单对象
    public static bodyMenu:ContextMenuCreator
    public static nodeMenu:ContextMenuCreator
    public static pointMenu:ContextMenuCreator
    public static connectMenu:ContextMenuCreator
    public static makeUserMenu:ContextMenuCreator//制单人查看

    //初始化右键菜单
    public static initContextMenu():void{
        FlowDesignVar.bodyContextMenu();
        FlowDesignVar.nodeContextMenu();
        FlowDesignVar.pointContextMenu();
        FlowDesignVar.connectContextMenu();
        FlowDesignVar.makeUserContextMenu();
    }
    //body右键菜单
    public static bodyContextMenu():void{
        //body右键菜单选项
        let bodyMenu= new ContextMenuCreator("bodyMenu", "bodyMenu", 100, 25);
        bodyMenu.addItem(new MenuItem("addProcessProperty", "设置流程属性", "menuHandle"));
        bodyMenu.addItem(new MenuItem("saveDiagram", "保存", "menuHandle"));
        FlowDesignVar.bodyMenu=bodyMenu;
    }
    //节点右键菜单选项
    public static nodeContextMenu():void{
        let nodeMenu = new ContextMenuCreator("nodeMenu", "nodeMenu", 100, 25);
        nodeMenu.addItem(new MenuItem("editNode", "编辑步骤", "menuHandle"));
        nodeMenu.addItem(new MenuItem("deleteNode", "删除", "menuHandle"));
        FlowDesignVar.nodeMenu=nodeMenu;
    }
    //转折点右键菜单选项
    public static pointContextMenu():void{
        let pointMenu = new ContextMenuCreator("pointMenu", "pointMenu", 100, 25);
        pointMenu.addItem(new MenuItem("deletePoint", "删除", "menuHandle"));
        FlowDesignVar.pointMenu=pointMenu;
    }
    //节点之间上的连线文本节点右键菜单选项
    public static connectContextMenu():void{
        let connectMenu = new ContextMenuCreator("nodeMenu", "nodeMenu", 100, 25);
        connectMenu.addItem(new MenuItem("editConnect", "设置转移", "menuHandle"));
        connectMenu.addItem(new MenuItem("changeDirection", "改变提交方向", "menuHandle"));
        connectMenu.addItem(new MenuItem("deleteConnect", "删除", "menuHandle"));
        FlowDesignVar.connectMenu=connectMenu;
    }

    //制单人催单右键菜单选项
    public static makeUserContextMenu():void{
        let makeUserMenu = new ContextMenuCreator("makeUserMenu", "makeUserMenu", 100, 25);
        makeUserMenu.addItem(new MenuItem("cb", "催办", "menuHandle"));
        FlowDesignVar.makeUserMenu=makeUserMenu;
    }
}